import * as React from 'react'
import { Fragment } from 'react'
import { ChatAltIcon, CloudUploadIcon, LightningBoltIcon, SparklesIcon, TagIcon, UserCircleIcon } from '@heroicons/react/solid'

const activity = [
  {
    id: 1,
    type: 'request',
    person: { name: 'Sheri P', href: '#' },
    imageUrl:
      'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    comment:
      '',
    date: '6d ago',
  },
  {
    id: 2,
    type: 'comment',
    person: { name: 'Sheri P', href: '#' },
    imageUrl:
      'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    comment:
      'I have a show in two weeks and am really struggling to find the right lead off jumps with tight turns. I\'ve got a few videos from a clinic I went to recently. Would you be able to take a look?',
    date: '6d ago',
  },
  {
    id: 3,
    type: 'accept',
    person: { name: 'Mary G', href: '#' },
    assigned: { name: 'Kristin Watson', href: '#' },
    date: '5d ago',
  },
  {
    id: 2,
    type: 'comment',
    person: { name: 'Mary G', href: '#' },
    imageUrl:
      'https://images.unsplash.com/photo-1593529467220-9d721ceb9a78?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    comment:
      'I\'d love to help! As soon you upload your videos I\'ll send you some notes.',
    date: '5d ago',
  },
  {
    id: 4,
    type: 'upload',
    person: { name: 'Sheri P', href: '#' },
    date: '4d ago',
  },
  {
    id: 5,
    type: 'comment',
    person: { name: 'Sheri P', href: '#' },
    imageUrl:
      'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.',
    date: '2h ago',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const LessonTimeline = () => (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {activity.map((activityItem, activityItemIdx) => (
            <li key={activityItem.id}>
              <div className="relative pb-8">
                {activityItemIdx !== activity.length - 1 ? (
                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                ) : null}
                <div className="relative flex items-start space-x-3">
                  {activityItem.type === 'comment' ? (
                      <>
                        <div className="relative">
                          <img
                            className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                            src={activityItem.imageUrl}
                            alt=""
                          />

                          <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                            <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div>
                            <div className="text-sm">
                              <a href={activityItem.person.href} className="font-medium text-gray-900">
                                {activityItem.person.name}
                              </a>
                            </div>
                            <p className="mt-0.5 text-sm text-gray-500">Commented {activityItem.date}</p>
                          </div>
                          <div className="mt-2 text-sm text-gray-700">
                            <p>{activityItem.comment}</p>
                          </div>
                        </div>
                      </>
                  ) : activityItem.type === 'accept' ? (
                      <>
                        <div>
                          <div className="relative px-1">
                            <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                              <SparklesIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                        <div className="min-w-0 flex-1 py-1.5">
                          <div className="text-sm text-gray-500">
                            <a href={activityItem.person.href} className="font-medium text-gray-900">
                              {activityItem.person.name}
                            </a>{' '}
                            accepted{' '}
                              <span className="whitespace-nowrap">{activityItem.date}</span>
                          </div>
                        </div>
                      </>
                  ) : activityItem.type === 'request' ? (
                      <>
                        <div>
                          <div className="relative px-1">
                            <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                              <LightningBoltIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                        <div className="min-w-0 flex-1 py-1.5">
                          <div className="text-sm text-gray-500">
                            <a href={activityItem.person.href} className="font-medium text-gray-900">
                              {activityItem.person.name}
                            </a>{' '}
                            requested a video review{' '}
                            <span className="whitespace-nowrap">{activityItem.date}</span>
                          </div>
                        </div>
                      </>
                  ) : activityItem.type === 'upload' ? (
                      <>
                        <div>
                          <div className="relative px-1">
                            <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                              <CloudUploadIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                        <div className="min-w-0 flex-1 py-1.5">
                          <div className="text-sm text-gray-500">
                            <a href={activityItem.person.href} className="font-medium text-gray-900">
                              {activityItem.person.name}
                            </a>{' '}
                            uploaded 3 videos{' '}
                            <span className="whitespace-nowrap">{activityItem.date}</span>
                          </div>
                        </div>
                      </>
                  ) : null}
                </div>
              </div>
            </li>
        ))}
      </ul>
    </div>
)

export default LessonTimeline
